<template>
  <div style="height: 100%">
    <Toast ref="toast" />
    <div
      ref="curDiv"
      v-show="isshowcalender"
      style="z-index: 999; position: absolute"
    >
      <Calender ref="Calender" v-on:choseDay="clickDay"></Calender>
    </div>
    <!-- <div ref="print" id="print" class="printList" >
      test
    </div> -->
    <!-- <vueEasyPrint sheetShow ref="easyPrint">
      <template>
        <printList
          :sheetData="sheetdata"
          :exportInfoBrief="exportinfo"
        ></printList>
      </template>
    </vueEasyPrint> -->

    <div class="grid-form1" style="padding: 0">
      <div style="margin: 0; padding: 0; vertical-align: middle; " class="search_condition_f">
        <div class="search_condition">
          <!-- <div class="pname">
            <label for="txtContractNumber" class="control-label"
              >搜索内容</label
            >
          </div>
          <div class="pname_value">
            <input
              type="text"
              class="form-control1"
              id="txtContractNumber"
              name="txtContractNumber"
              placeholder="商品名称/订单编号"
              v-model.trim="searchpname"
              @keyup.enter="search"
            />
          </div> -->

          <div class="bdate">
            <label for="txtBDate" class="control-label">下单日期从</label>
          </div>
          <div class="bdate_value">
            <input
              type="text"
              class="form-control1"
              id="txtBDate"
              name="txtBDate"
              readonly
              @click.stop="showcalender($event, 'b')"
              v-model.trim="searchbdate"
            />
          </div>
          <div>
            <select name="sltBHour" class="bdate_value_time" v-model="sBHour">
              <option
                v-for="(item, index) in listhours"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div>
            <select
              name="sltBMinute"
              class="bdate_value_time"
              v-model="sBMinute"
            >
              <option
                v-for="(item, index) in listsecond"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div>
            <select
              name="sltBSecond"
              class="bdate_value_time"
              v-model="sBSecond"
            >
              <option
                v-for="(item, index) in listsecond"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div class="edate">
            <label for="txtEDate" class="control-label">截止到</label>
          </div>
          <div class="edate_value">
            <input
              type="text"
              class="form-control1"
              id="txtEDate"
              name="txtEDate"
              readonly
              @click.stop="showcalender($event, 'e')"
              v-model.trim="searchedate"
            />
          </div>
          <div>
            <select name="sltEHour" class="bdate_value_time" v-model="sEHour">
              <option
                v-for="(item, index) in listhours"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div>
            <select
              name="sltEMinute"
              class="bdate_value_time"
              v-model="sEMinute"
            >
              <option
                v-for="(item, index) in listsecond"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <div>
            <select
              name="sltESecond"
              class="bdate_value_time"
              v-model="sESecond"
            >
              <option
                v-for="(item, index) in listsecond"
                :key="index"
                :value="item"
              >
                {{ item }}
              </option>
            </select>
          </div>
          <!-- <div class="sltStyle">
            <select name="sltReferee" v-model="searchReferee" @change="setIDShow">
              <option value="">统计维度</option>
              <option value="1">按商品类别</option>
              <option value="2">按商品名称</option>
              <option value="3">指定具体商品</option>
            </select>
          </div> -->
          <div class="edate_value">
            <input
              type="text"
              class="form-control1"
              id="txtEDate"
              name="txtEDate"
              placeholder="具体的商品ID，多个ID示例：32,33"
              v-model.trim="searchpidlist"
              style="width:250px;"
              v-if="isshow"
            />
          </div>
        </div>
        <div class="search_condition">
          <div class="sltStyle" style="padding-top: 2px; padding-left: 50px">
            <button class="btn btn-default" type="button" @click="clearSearch">
              <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;清空
            </button>
            <button class="btn btn-default" type="button" @click="search(1)">
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;按产品销售统计
            </button>
            <button class="btn btn-default" type="button" @click="search(2)">
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;按地区销售统计
            </button>
            <button class="btn btn-default" type="button" @click="search(3)">
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;按销售利润统计
            </button>
            <download-excel
              class="btn btn-default"
              :fetch="fetchDataR"
              :fields="jsonFieldsR"
              :name="filenameR"
            >
              <i class="fa fa-table" aria-hidden="true"></i>&nbsp;导出统计结果
            </download-excel>
          </div>
          <!-- <div class="sltStyle">
            <select name="sltPayStatus" v-model="orderStatus">
              <option value="">订单状态</option>
              <option value="0">待付款</option>
              <option value="1">待发货</option>
              <option value="2">待收货</option>
              <option value="3">待评论</option>
              <option value="4">退货</option>
              <option value="5">已完成</option>
              <option value="6">已关闭</option>
            </select>
          </div> -->
          
        </div>
        <div class="search_condition">
          
          <!-- <div class="sltStyle">
            <select name="sltPayStatus" v-model="deliverWeekDay">
              <option value="">正常配送</option>
              <option value="周一">周一配送</option>
              <option value="周二">周二配送</option>
              <option value="周三">周三配送</option>
              <option value="周四">周四配送</option>
              <option value="周五">周五配送</option>
              <option value="周六">周六配送</option>
              <option value="周日">周日配送</option>
            </select>
          </div> -->
          <!-- <div class="sltStyle">
            <select
              name="sltProductClass"
              v-model="productFClass"
              @change="setCClass"
            >
              <option value="">请选择商品类别</option>
              <option
                :value="item.fcid"
                v-for="(item, index) in classFList"
                :key="index"
              >
                {{ item.fcname }}
              </option>
            </select>
            <select name="sltProductCClass" v-model="productCClass" @change="getCClassName">
              <option value="">请选择二级分类</option>
              <option
                :value="item.ccid"
                v-for="(item, index) in classCList"
                :key="index"
              >
                {{ item.ccname }}
              </option>
            </select>
          </div> -->
          <!-- <div class="cname">
            <label for="txtContractName" class="control-label">联系人</label>
          </div> -->

          <!-- <div class="cname_value">
            <input
              type="text"
              class="form-control1"
              id="txtContractName"
              name="txtContractName"
              v-model.trim="searchpcontact"
              @keyup.enter="search"
            />
          </div> -->
          
        </div>
        
      </div>
    </div>
    <div class="orderlist" v-if="stype==1">
        <div class="order_item" v-if="orderList.length > 0">
            <div class="order_item_1" style="//border-bottom: 1px solid #eee;">统计周期</div>
            <div class="order_item_7" style="text-align:center;">商品名称</div>
            <!-- <div class="order_item_5">商品类别</div> -->
            <div class="order_item_2">规格</div>
            <div class="order_item_3">总销售量</div>
            <div class="order_item_4">总销售额</div>
            <!-- <div class="order_item_6">&nbsp;</div> -->
        </div>
      <div
        class="order_item"
        v-for="(item, index) in orderList"
        :key="index"
        style="font-weight: bold;"
      >
        <div class="order_item_1" style="//border-bottom: 1px solid #eee;">{{caltimescope}}</div>
        <div class="order_item_7">{{item.pname}}</div>
        <!-- <div class="order_item_3">{{item.fclassname}}</div> -->
        <div class="order_item_2">{{item.size}}</div>
        <div class="order_item_5">{{item.num}}</div>
        <div class="order_item_6">{{item.saleprice}}</div>
        <!-- <div class="order_item_6">{{item.saleprice}}</div> -->
      </div>
      <div v-if="collapseB">
      <div
        
        v-for="(item, index) in orderListF"
        :key="index"
        
      >
        <div class="order_item">
            <div class="order_item_1"></div>
            <div class="order_item_7">{{item.fclass}}</div>
            <div class="order_item_2">{{item.pprice}}</div>
            <div class="order_item_3">{{item.costprice}}</div>
            <div class="order_item_4">{{item.profit}}</div>
            <div class="order_item_5">{{item.profitrate}}%</div>
            <div class="order_item_6" v-if="!item.collapse"><a @click="getCClassCal(index,item.fclassid)">展开</a></div>
            <div class="order_item_6" v-else><a @click="closeCList(index)">收起</a></div>
        </div>
        <div v-if="item.collapse">
        <div class="order_item" style="font-style:italic;font-size:0.9em;" v-for="(s, pi) in orderListC[index]"
        :key="pi">
            <div class="order_item_1"></div>
            <div class="order_item_7" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{s.cclass}}</div>
            <div class="order_item_2">{{s.pprice}}</div>
            <div class="order_item_3">{{s.costprice}}</div>
            <div class="order_item_4">{{s.profit}}</div>
            <div class="order_item_5">{{s.profitrate}}%</div>
            <div class="order_item_6">&nbsp;</div>
        </div>
        </div>
      </div>
      </div>

      <div class="noresult" v-if="shownoresult">
        没有找到符合条件的统计结果！
      </div>
    </div>
    <div class="orderlist" v-else-if="stype==2">
        <div class="order_item" v-if="orderList.length > 0">
            <div class="order_item_1" style="//border-bottom: 1px solid #eee;">统计周期</div>
            <div class="order_item_7" style="text-align:center;">销售区域</div>
            <div class="order_item_3">SKU总销售量</div>
            <div class="order_item_4">总销售额</div>
            <!-- <div class="order_item_6">&nbsp;</div> -->
        </div>
      <div
        class="order_item"
        v-for="(item, index) in orderList"
        :key="index"
        style="font-weight: bold;"
      >
        <div class="order_item_1" style="//border-bottom: 1px solid #eee;">{{caltimescope}}</div>
        <div class="order_item_7">{{item.shi}}</div>
        <div class="order_item_5">{{item.allnum}}</div>
        <div class="order_item_6">{{item.allprice}}</div>
        <!-- <div class="order_item_6">{{item.saleprice}}</div> -->
      </div>
      <div v-if="collapseB">
      <div
        
        v-for="(item, index) in orderListF"
        :key="index"
        
      >
        <div class="order_item">
            <div class="order_item_1"></div>
            <div class="order_item_7">{{item.fclass}}</div>
            <div class="order_item_2">{{item.pprice}}</div>
            <div class="order_item_3">{{item.costprice}}</div>
            <div class="order_item_4">{{item.profit}}</div>
            <div class="order_item_5">{{item.profitrate}}%</div>
            <div class="order_item_6" v-if="!item.collapse"><a @click="getCClassCal(index,item.fclassid)">展开</a></div>
            <div class="order_item_6" v-else><a @click="closeCList(index)">收起</a></div>
        </div>
        <div v-if="item.collapse">
        <div class="order_item" style="font-style:italic;font-size:0.9em;" v-for="(s, pi) in orderListC[index]"
        :key="pi">
            <div class="order_item_1"></div>
            <div class="order_item_7" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{s.cclass}}</div>
            <div class="order_item_2">{{s.pprice}}</div>
            <div class="order_item_3">{{s.costprice}}</div>
            <div class="order_item_4">{{s.profit}}</div>
            <div class="order_item_5">{{s.profitrate}}%</div>
            <div class="order_item_6">&nbsp;</div>
        </div>
        </div>
      </div>
      </div>

      <div class="noresult" v-if="shownoresult">
        没有找到符合条件的统计结果！
      </div>
    </div>
    <div class="orderlist" v-else-if="stype==3">
        <div class="order_item" v-if="orderList.length > 0">
            <div class="order_item_1" style="//border-bottom: 1px solid #eee;">统计周期</div>
            <div class="order_item_7" style="text-align:center;">商品名称</div>
            <div class="order_item_2">规格</div>
            <div class="order_item_4">总销售额</div>
            <div class="order_item_3">总成本</div>
            <div class="order_item_4">总利润</div>
            <div class="order_item_4">利润率</div>
            <!-- <div class="order_item_6">&nbsp;</div> -->
        </div>
      <div
        class="order_item"
        v-for="(item, index) in orderList"
        :key="index"
        style="font-weight: bold;"
      >
        <div class="order_item_1" style="//border-bottom: 1px solid #eee;">{{caltimescope}}</div>
        <div class="order_item_7">{{item.pname}}</div>
        <div class="order_item_5">{{item.size}}</div>
        <div class="order_item_6">{{item.saleprice}}</div>
        <div class="order_item_6">{{item.costprice}}</div>
        <div class="order_item_6">{{item.profit}}</div>
        <div class="order_item_6">{{item.profitrate}}</div>
        <!-- <div class="order_item_6">{{item.saleprice}}</div> -->
      </div>
      <div v-if="collapseB">
      <div
        
        v-for="(item, index) in orderListF"
        :key="index"
        
      >
        <div class="order_item">
            <div class="order_item_1"></div>
            <div class="order_item_7">{{item.fclass}}</div>
            <div class="order_item_2">{{item.pprice}}</div>
            <div class="order_item_3">{{item.costprice}}</div>
            <div class="order_item_4">{{item.profit}}</div>
            <div class="order_item_5">{{item.profitrate}}%</div>
            <div class="order_item_6" v-if="!item.collapse"><a @click="getCClassCal(index,item.fclassid)">展开</a></div>
            <div class="order_item_6" v-else><a @click="closeCList(index)">收起</a></div>
        </div>
        <div v-if="item.collapse">
        <div class="order_item" style="font-style:italic;font-size:0.9em;" v-for="(s, pi) in orderListC[index]"
        :key="pi">
            <div class="order_item_1"></div>
            <div class="order_item_7" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{s.cclass}}</div>
            <div class="order_item_2">{{s.pprice}}</div>
            <div class="order_item_3">{{s.costprice}}</div>
            <div class="order_item_4">{{s.profit}}</div>
            <div class="order_item_5">{{s.profitrate}}%</div>
            <div class="order_item_6">&nbsp;</div>
        </div>
        </div>
      </div>
      </div>

      <div class="noresult" v-if="shownoresult">
        没有找到符合条件的统计结果！
      </div>
    </div>

    
  </div>
  <!-- </div> -->
</template>

<script>
// import axios from "axios";
import JsonExcel from 'vue-json-excel'
import Toast from "../../components/Toast";
import vueEasyPrint from "vue-easy-print";
// import printList from "./printList";

import Calender from "../../components/vue-calendar-component";

export default {
  name: "saleslist",
  components: {
    Calender,
    Toast,
    "download-excel":JsonExcel,
    vueEasyPrint,
    // printList,
  },
  data() {
    return {
        collapseB:false,
        collapseF:false,
      productFClass: "",
      productCClass: "",
    //   productCName: "",
    //   deliverWeekDay: "",
      classFList: [],
      classCList: [],
      classRFList: [],
      searchReferee: "",
      nowtime: "",
      selectedordernum: 0,
      orderStatus: "",
      exportList: [],
      // jsonFields: {  //导出Excel表格的表头设置
      //     '商品名称': 'pname',
      //     '规格': 'psize',
      //     '数量': 'psum',
      //     '金额': 'pprice',
      //     '商品类别': 'pclassname'
      // },
      jsonFieldsR: {  //导出Excel表格的表头设置
            '统计周期':'pdatescope',
            '商品类别': 'pfclass',
            '商品子类别': 'pcclass',
            '商品名称': 'pname',
            '规格': 'size',
            '销售数量': 'num',
            '销售总额':'saleprice'
        },
        filenameR:'',
        stype:1,
      listhours: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
      ],
      listsecond: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
      ],
      // filename:this.GLOBAL.getToday() + '出库总单.xls',
      allocateDate:this.GLOBAL.getToday(),
      searchedate: "",
      searchbdate: "",
      searchedatef: "",
      searchbdatef: "",
      searchedatefresult: "",
      searchbdatefresult: "",
      searchRefereeresult: "",
      searchpidlistresult: "",
      caltimescope:"",
      shownoresult: false,
      orderList: [],
      orderListF: [],
      orderListC: [],
      currentIndex: -1,
      currentpage: 1,
      gotopage: "",
      searchpcontact: "",
      searchpname: "",
      isshowcalender: false,
    //   show: false,
      isshow: false,
      //   isshowupdate: false,
      win_height: "",
      currentSID: 0,
      currentDateS: "",
      sEHour: "00",
      sEMinute: "00",
      sESecond: "00",
      sBHour: "00",
      sBMinute: "00",
      sBSecond: "00",
      sheetdata: [],
      exportinfo: [],
      searchpidlist:""
    };
  },
//   watch: {
//     nowtime() {
//       this.refreshorder();
//     },
//   },
  methods: {
      setIDShow(){
          if(this.searchReferee=="3"){
              this.isshow=true
          }else{
              this.isshow=false
              this.searchpidlist=""
          }
      },
    async fetchDataR(){
      
      let vflag = false
      let idlist='0'
      
      if(this.searchRefereeresult==""){
          this.$refs.toast.toast("请先点统计按钮")
          return false
      }
      let tmpFilename=""
      switch(this.stype)
      {
        case 1:
          tmpFilename="按产品销售统计表.xls"
          this.jsonFieldsR= {  //导出Excel表格的表头设置
              '统计周期':'pdatescope',
              '商品类别': 'pfclass',
              '商品子类别': 'pcclass',
              '商品名称': 'pname',
              '规格': 'size',
              '销售数量': 'num',
              '销售总额':'saleprice'
          }
          break
        case 2:
          tmpFilename="按地区销售统计表.xls"
          this.jsonFieldsR= {  //导出Excel表格的表头设置
              '统计周期':'pdatescope',
              '销售区域': 'shi',
              '销售SKU数量': 'allnum',
              '总销售额': 'allprice'
          }
          break
        case 3:
          tmpFilename="按销售利润统计表.xls"
          this.jsonFieldsR= {  //导出Excel表格的表头设置
              '统计周期':'pdatescope',
              '商品类别': 'pfclass',
              '商品子类别': 'pcclass',
              '商品名称': 'pname',
              '规格': 'size',
              '总销售额': 'saleprice',
              '总成本':'costprice',
              '总利润': 'profit',
              '利润率': 'profitrate'
          }
          break
      }
      this.filenameR=tmpFilename
    //   let tmpAction=""
    // if(this.searchRefereeresult=="1"){
    //     this.filenameR='按商品类别利润统计表.xls'
    // }else if(this.searchRefereeresult=="2"){
    //     this.filenameR='按商品名称利润统计表.xls'
    // }else{
    //     this.filenameR='具体商品利润统计表.xls'
    // }
    //   if(this.searchRefereeresult=="1"){
    //       tmpAction="/exportProfitExcel/"
    //   }
        // const res = await this.$get(this.GLOBAL.serverAddress + "/exportSalesExcel/", 
        // {   profitType:this.searchRefereeresult,
        //     bdate: this.searchbdatefresult,
        //     edate: this.searchedatefresult,
        //     idlist: this.searchpidlistresult
        // });
        // console.log(res.data);
          
        // if (res.status === 200) {
        //   if(res.data.length > 0){
        //     // setTimeout(()=>{
        //     //   this.$router.go(0)
        //     // },1500)  
        //     return res.data
        //     // this.exportList=res.data
        //   }else{
        //     this.$refs.toast.toast("未查到相关订单")
        //     return false
        //   }
        // }
      return this.orderList
      
    },
    
    // printOrder(){

    //   let tmpDom=this.$refs.print

    //   console.log(tmpDom)

    //   this.$print(tmpDom)
    // },
    viewOrderDetail(id) {
      this.$router.push("/retailorderdetail?from=1&id=" + id+"&page="+this.currentpage);
    },
    editOrder(id) {
      this.$router.push("/updateretailorder?from=1&id=" + id+"&page="+this.currentpage);
    },
    
    hideCalender() {
      this.isshowcalender = false;
    },
    clickDay(data) {
      //   this.searchyxqdate = data;
      // console.log(this.currentDateS)
      if (this.currentDateS === "b") {
        this.searchbdate = data;
      }else if (this.currentDateS === "a") {
        this.allocateDate = data;
      } else {
        this.searchedate = data;
      }
      this.isshowcalender = false;
    },
    showcalender(e, v) {
      var divC = this.$refs.curDiv;
      this.currentDateS = v;
      divC.style.top = e.target.getBoundingClientRect().top + 40 + "px";
      divC.style.left = e.target.getBoundingClientRect().left + "px";

      this.isshowcalender = true;
    },

    clearSearch() {
        this.collapseB=false;
    //   this.deliverWeekDay = "";
    //   this.searchpname = "";
    //   this.searchpcontact = "";
      this.searchReferee = "";
      this.isshow=false;
      this.searchpidlist=""
    //   this.productFClass = "";
    //   this.productCClass = "";
    //   this.productCName = "";
      this.filenameR="";
      this.searchedate = "";
      this.searchbdate = "";
    //   this.orderStatus = "";
      this.sEHour = "00";
      this.sEMinute = "00";
      this.sESecond = "00";
      this.sBHour = "00";
      this.sBMinute = "00";
      this.sBSecond = "00";
    //   this.cancelAllList();
    },
    
    
    
    async getCClassCal(pi,fid){
        let tmpFun=""
        if(this.searchRefereeresult=="1"){
            tmpFun="/calCClassProfitList/"
        }else{
            tmpFun="/calProfitListProductSize/"
        }

        const res = await this.$get(
        this.GLOBAL.serverAddress + tmpFun,
        {
        //   pname: this.searchpname,
        //   pclass: tmpClass,
        //   contact: this.searchpcontact,
          bdate: this.searchbdatefresult,
          edate: this.searchedatefresult,
          fid:fid
        
        }
      );
      console.log(res);

      if (res.status === 200) {
        //   this.orderListC[pi].collapse=true
        this.orderListF[pi].collapse=true
          Vue.set(this.orderListC,pi,res.data)
        // this.orderListC[pi] = res.data;
        // this.collapseF=true
        // console.log(this.orderListC)
        // if (this.orderListF.length > 0) {
        //   this.shownoresult = false;
        //   for(var i = 0; i < this.orderListF.length;i++) {
        //       this.orderListC.push([])
        //   }
        // } else {
        //   this.shownoresult = true;
        // }
      }
    },
    closeFList(){
        this.collapseB=false
    },
    closeCList(pi){
        this.orderListF[pi].collapse=false
    },
    async getFClassCal(){
        let tmpFun=""
        if(this.searchRefereeresult=="1"){
            tmpFun="/calProfitListPFClass/"
        } else{
            tmpFun="/calProfitListProduct/"
        }
        const res = await this.$get(
        this.GLOBAL.serverAddress + tmpFun,
        {
        //   pname: this.searchpname,
        //   pclass: tmpClass,
        //   contact: this.searchpcontact,
          bdate: this.searchbdatefresult,
          edate: this.searchedatefresult,
          idlist: this.searchpidlistresult
        //   status: this.orderStatus,
        //   profittype: 2,
        //   delweedDay: this.deliverWeekDay,
        //   scope:"1"
        }
      );
      console.log(res);

      if (res.status === 200) {

        this.orderListF = res.data;
        if (this.orderListF.length > 0) {
          this.shownoresult = false;
          for(var i = 0; i < this.orderListF.length;i++) {
              this.orderListC.push([])
          }
          this.collapseB=true;
        } else {
          this.shownoresult = true;
        }
      }
    },
    async search(stype) {
      this.stype=stype
    //   this.currentpage = 1;
    //   this.selectedordernum = 0;
    //   let tmpClass = "";
    //   if (this.productCClass != "") {
    //     tmpClass = this.productCClass;
    //   } else {
    //     tmpClass = this.productFClass;
    //   }

        // if(this.searchReferee=="") {
        //     this.$refs.toast.toast("请选择统计维度");
        //     return false;
        // }
        // if(this.searchReferee=="3"){
        //     if(this.searchpidlist==""){
        //         this.$refs.toast.toast("请输入商品ID");
        //         return false;
        //     }
        // }
      if (this.searchbdate != "") {
        this.searchbdatef =
          this.searchbdate +
          " " +
          this.sBHour +
          ":" +
          this.sBMinute +
          ":" +
          this.sBSecond;
        this.caltimescope="从" + this.searchbdatef
      } else {
        this.searchbdatef = "";
        this.caltimescope="从开始"
      }
      if (this.searchedate != "") {
        this.searchedatef =
          this.searchedate +
          " " +
          this.sEHour +
          ":" +
          this.sEMinute +
          ":" +
          this.sESecond;
        this.caltimescope=this.caltimescope+"到"+this.searchedatef
      } else {
        this.searchedatef = "";
        this.caltimescope=this.caltimescope+"到现在"
      }
      this.orderListF=[]
      this.collapseB=false;
      this.searchbdatefresult=this.searchbdatef
      this.searchedatefresult=this.searchedatef
      this.searchRefereeresult="1"//this.searchReferee
      this.searchpidlistresult=this.searchpidlist
      const res = await this.$get(
        this.GLOBAL.serverAddress + "/searchSalesList/",
        {
          bdate: this.searchbdatefresult,
          edate: this.searchedatefresult,
          stype:stype
        //   idlist: this.searchpidlistresult,
        //   profittype: this.searchRefereeresult,
        
        }
      );
      console.log(res);

      if (res.status === 200) {

        this.orderList = res.data;
        if (this.orderList.length > 0) {
          this.shownoresult = false;
        } else {
          this.shownoresult = true;
        }
      }
      
    },
  },
  
  mounted() {
    
    this.haveRights("|0301|");
    // this.search();
    window.addEventListener("click", this.hideCalender);
    this.win_height = window.innerHeight;
    // if(this.$route.params.page)
    // {
    //   this.currentpage=parseInt(this.$route.params.page)
    //   // console.log(this.pagecount)
    //   // this.getNewPage(2)
    // }
  },
  beforeDestroy() {
    // 实例销毁之前对点击事件进行解绑
    window.removeEventListener("click", this.hideCalender);
  },
//   destroy() {
//     clearTimeout(this.refreshorder);
//   },
};
</script>

<style scoped lang="less">
.btnexport {
  padding-top: 10px;
  padding-left: 50px;
  display: flex;
  align-items: center;
}
.printList {
  position: absolute;
  left: 300px;
}
.bdate_value_time {
  color: #9c9c9c;
  border: 0px solid #9c9c9c;
}
.search_condition_f{
  display: flex;
  flex-direction: column;

  .search_condition {
    display: flex;
    // white-space: nowrap;
    justify-content: space-around;
    align-items: center;
    text-align: right;
    padding: 10px;
    .pname {
      //   display: flex;
      flex: 1;
      padding-right: 5px;
    }
    .sltStyle {
      flex: 1;
      padding-right: 20px;
      color: #999;
      display: flex;
      select {
        border: 1px solid #e9e9e9;
        font-size: 0.9em;
        outline: none;
        padding: 0.5em 1em;
        color: #999;
      }
    }
    .pname_value {
      flex: 2;
      
    }

    .cname {
      flex: 1;
      padding-right: 5px;
      white-space: nowrap;
    }

    .cname_value {
      flex: 1;
      // border: 1px solid red;
    }

    .bdate {
      flex: 1;
      padding-right: 5px;
      white-space: nowrap;
    }

    .bdate_value {
      flex: 1;
    }

    .edate {
      //   border: 1px solid green;
      flex: 1;
      text-align: center;
      // display: flex;
      // align-items: center;
      // justify-content: center;
    }

    .edate_value {
      flex: 1;
    }
  }
}

.orderlist {
  color: #9c9c9c;
  background-color: #fff;  
  padding:10px;
  .order_item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
        border-bottom: 1px solid #eee;
      
      .order_item_1{
          flex: 2;
          
      }
      .order_item_2{
          flex: 1;
        //   //border-bottom: 1px solid #eee;
          white-space: nowrap;
          
      }
      .order_item_3{
          flex: 1;
          //border-bottom: 1px solid #eee;
      }
      .order_item_4{
          flex: 1;
          //border-bottom: 1px solid #eee;
      }
      .order_item_5{
          flex: 1;
          //border-bottom: 1px solid #eee;
      }
      .order_item_6{
          flex: 1;
          //border-bottom: 1px solid #eee;
      }
      .order_item_7{
          flex: 4;
          //border-bottom: 1px solid #eee;
          text-align: left;
      }
    //   width:100%;
    // margin-top: 15px;
    padding: 5px;

    
    // padding-top:15px;
    .order_item_briefinfo {
      display: flex;
      border-bottom: 1px solid #eee;
      //   padding-bottom:10px;
      padding: 10px;
      .order_item_briefinfo_orderchk {
        input{
          // outline: 1px solid red;
          // border:0px solid black;
          transform:scale(1.8, 1.8);
        }
      }
      .order_item_briefinfo_ordernum {
        padding-left: 20px;
        flex: 2;
      }

      .order_item_briefinfo_orderdate {
        flex: 2;
        white-space: nowrap;
        word-break: none;
      }
      .order_item_briefinfo_orderpay {
        flex: 1;
        text-align: center;
      }
    }

    .order_item_content {
      display: flex;
      .order_item_content_product {
        flex: 3;
        margin: 10px;
        padding-left: 10px;
        display: flex;
        .productinfo_img {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 80%;
            max-height: 100px;
            object-fit: contain;
          }
        }

        .productinfo {
          flex: 3;
          margin: 10px;
          padding-left: 5px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          // align-items: center;
          .productinfo_title {
          }

          .productinfo_guige {
          }

          .productinfo_num {
          }

          .productinfo_price {
          }
        }
      }

      .order_item_content_order {
        flex: 5;
        display: flex;
        .orderremark {
          flex: 2;
          padding: 5px;
          display: flex;
          align-items: center;
          color: #ff4500;
        }
        .orderprice {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 1.2em;
          color: #6e8b3d;
        }

        // .orderstatus {
        //   flex: 1;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        // }

        .ordercontact {
          flex: 3;
          display: flex;
          // align-items: center;
          justify-content: center;
          flex-direction: column;
          padding-left: 10px;
          .ordercontact_name {
          }

          .ordercontact_tel {
          }

          .ordercontact_address {
            display: flex;
            .address_title {
              white-space: nowrap;
            }
          }
        }
      }

      .order_item_content_opr {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // padding:15px;
        button {
          width: 80%;
          margin: 3px;
        }
      }
    }
  }
}

button {
  background-color: #f5f5f5;
  margin-right: 10px;
}
.btmInfo {
  background: none;
  border: none;
  outline: none;
  color: #333;
  font-size: 0.9em;
}
.noresult {
  text-align: center;
  font-size: 2em;
  color: rgb(160, 154, 154);
}
.pagenum_info {
  background: #fff;
  height: 50px;
  margin-top: 20px;
  padding: 10px;
  color: #6c757d;
  // border-top: 1px dashed #9acd32;
}
.actived {
  background-color: rgba(190, 221, 140, 0.9);
}
table.dataintable {
  /* margin-top: 15px; */
  border-collapse: collapse;
  border: 1px solid white;
  width: 98%;
  font-size: 0.95em;
  background-color: #e5ebdc;
  line-height: 40px;
}
table.dataintable th {
  vertical-align: middle;
  /* padding:5px 15px 5px 6px; */
  background-color: #e8e8e8;
  border: 1px solid white;
  text-align: center;
  color: rgb(173, 169, 169);
}
table.dataintable td {
  vertical-align: text-top;
  /* padding:6px 15px 6px 6px; */
  border: 1px solid white;
  line-height: 35px;
}

.hidden_view {
  width: 100%;
  background: #698b22;
  opacity: 0.2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}
</style>